/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --expandable-content-color: #{$black};
    --expandable-content-divider-color: var(--primary-divider-color);
}

@mixin expanded-content {
    padding: 1.2rem;
    opacity: 1;
    max-height: 100%;

    @include mobile {
        padding: 1.4rem;
    }
}

.ExpandableContent {
    &-Button {
        text-align: left;
        width: 100%;
        will-change: border-color;

        @include before-desktop {
            min-height: var(--header-nav-height);
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: .6rem 1.2rem;
        }

        @include mobile {
            padding: .7rem 1.4rem;
        }

        &::after,
        &::before {
            @include mobile {
                right: 2.1rem;
            }

            @include before-desktop {
                content: '';
            }

            position: absolute;
            height: 12px;
            width: 2px;
            background-color: var(--expandable-content-color);
            right: 1.8rem;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &::before {
            transform-origin: 50% 50%;
            transform: scale(1);
            transition: 200ms transform;
            will-change: transform;
        }

        &::after {
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }

        &_isContentExpanded {
            &::before {
                transform: scale(0);
            }
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-size: 1.6rem;
        font-weight: $fw-bold;
        font-family: $ff-pluto-heavy;
        line-height: 2rem;
        text-transform: none;
    }

    &-SubHeading {
        opacity: 0.65;

        @include desktop {
            font-size: 12px;
        }
    }

    &-Content {
        @include before-desktop {
            padding: 0 1.2rem;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        @include mobile {
            padding: 0 1.4rem;
        }

        &_isContentExpanded {
            @include before-desktop {
                @include expanded-content;
            }
        }
    }
}
