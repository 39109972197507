/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.Responsive_Block {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;

    .Image {
        height: 100%;
    }

    .Image_ratio_square {
        padding-bottom: 0;
        border-radius: 0.2rem;
    }

    .Responsive_Header {
        margin-bottom: 10px;
        text-transform: initial;
        margin-top: 0;
    }

    p {
        margin-top: 0px;
        font-weight: normal;
        margin-bottom: 0px;
    }

    img {
        width: 100%;
        height: 100%;
        padding-bottom: 0;
        position: relative;
    }

    button {
        width: 100%;

        @include desktop {
            width: auto;
        }
    }
}
