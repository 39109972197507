/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.Blurb_Block {
    a {
        color: inherit;
    }

    .container {
        display: flex;
    }

    .row {
        flex-direction: row;
        height: 100%;
    }

    .Desktop_Headline {
        display: none;
    }

    .Mobile_Headline {
        display: flex;
    }

    &-Figure {
        .Image_ratio_square {
            padding-bottom: 38%;
            border-radius: 0.2rem;

            @include tablet {
                padding-bottom: 45%;
            }
        }

        .Image-Image {
            object-fit: cover;
        }

        .Blurb_Block_Responsive_Image {
            display: none;
        }

        @include desktop {
            .Blurb_Block_Responsive_Image {
                display: flex;
            }
        }
    }

    &-Figcaption {
        .Image_ratio_square {
            width: 5rem;
            padding-bottom: 14%;
        }

        .Image {
            margin-right: 1.5rem;
            margin-top: auto;
            margin-bottom: auto;
        }

        .Headline {
            .Image {
                display: none;
            }
        }

        .Content {
            margin: auto;
        }

        p {
            max-width: 28rem;
            margin-bottom: 0;
            margin-top: 0.5rem;
        }

        strong {
            font-family: "PlutoHeavy";
            font-weight: 400;
            font-size: 1.6rem;
        }

        @include desktop {
            .Image_ratio_square {
                width: 7rem;
                padding-bottom: 24%;
            }

            .Blurb_Block_Responsive_Image {
                width: 50%;

                .Image_ratio_square {
                    width: 100%;
                    padding-bottom: 72%;
                    margin: 0;
                    margin-top: auto;
                }
            }

            .Headline_Header {
                h2 {
                    margin: 0;
                }
            }

            .Content {
                margin-left: 6.9%;

                p {
                    min-width: 100%;
                }
            }

            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(-50%);
        }

        @include tablet {
            .Blurb_Block_Responsive_Image {
                .Image_ratio_square {
                    margin-top: auto;
                }
            }

            .Image {
                margin-right: 0;
            }
        }
    }

    @include desktop {
        .Mobile_Headline {
            display: none;
        }

        .Desktop_Headline {
            display: flex;
            flex-direction: column;
        }

        .Headline {
            .Image {
                display: block;
            }

            display: flex;
            flex-direction: row;
        }
    }
}
