/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.Category_Block {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 11px;

    .Image_ratio_square {
        padding-bottom: 52%;
        border-radius: 0.2rem;
    }

    .Image-Image {
        object-fit: cover;
    }

    h1 {
        grid-column: span 8;
        text-align: center;
    }

    h3 {
        color: white;
    }

    &-Figure {
        grid-column: span 4;

        @include desktop {
            grid-column: span 2;
        }
    }

    &-Figcaption {
        position: absolute;
        bottom: 66%;
        left: 8%;
        transform: none;

        @include desktop {
            bottom: 5%;
            transform: translateY(-50%);
        }
    }

    @include desktop {
        gap: 20px;
    }
}
