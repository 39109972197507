/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

.ScheduleDelivery {
    margin-top: 30px;
    order: 20;

    @include mobile {
        margin-bottom: 20px;
        padding-right: 14px;
        padding-left: 14px;
        width: 100%;
    }

    &-Title {
        font-family: $ff-pluto-heavy;
        font-size: 16px;
    }

    &-Wrapper {
        display: flex;
        justify-content: space-between;
        max-width: 260px;
    }

    &-Element {
        .Field {
            label {
                font-size: 16px;

                .input-control {
                    margin-left: 0;
                    margin-right: 8px;
                }
            }
        }
    }

    &-Date {
        margin-top: 20px;

        label {
            font-family: $ff-pluto-heavy;
            font-size: 16px;
            padding-bottom: 12px;
        }

        input {
            text-transform: uppercase;
        }
    }
}
