.Body-text {
    font-size: 1.6rem;
    line-height: 1.25;

    &_regular {
        font-family: $ff-pluto-regular;
    }

    &_bold {
        font-family: $ff-pluto-heavy;
    }

    &_italic {
        font-style: italic;
    }
}

.Caption {
    font-size: 1.2rem;
    line-height: 1.2;
}
