/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchField {
    min-width: 12.6rem;

    &-PlaceHolder {
        &_type_search {
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 3px;
            background-color: $c-hbb-green;
            background-repeat: no-repeat;
            background-position: center;

            @include search-button-white;
        }
    }

    &-SearchIcon {
        @include search-icon;

        &::before {
            content: none;
        }
    }

    &-Input {
        padding-top: 1.2rem;
        padding-bottom: 1rem;
        border: 2px solid $c-hbb-grey;
        border-radius: 3px;
        background-color: $c-hbb-white;
        line-height: 2rem;
        font-family: $ff-pluto-regular;
        color: $c-hbb-black;

        @include desktop {
            padding-top: 0;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $c-hbb-black;
        }

        &::placeholder {
            color: $c-hbb-grey;
        }
    }

    &-Wrapper {
        margin: 0;

        .SearchField-Icon {
            display: flex;
            align-items: center;
            margin-left: 3.2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            cursor: pointer;

            &::after {
                @include search-icon;

                height: 20px;
                margin-left: 1rem;
            }
        }
    }

    &-CloseIcon {
        margin-left: 11rem;
        margin-bottom: 0.5rem;

        &::before {
            height: 20px;
            width: 20px;
        }
    }

    &-SearchWrapper_isVisible {
        z-index: 10;
    }

    &-SearchInnerWrapper {
        .SearchField {
            &-SearchIcon {
                content: '';

                &::after {
                    @include search-icon;

                    height: 20px;
                    width: 24px;
                    box-shadow: none;
                }
            }
        }
    }
}
