/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.Spotlight_Block {
    .container {
        display: flex;
    }

    .row {
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }
    }

    .column {
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @include desktop {
            width: 50%;
        }
    }

    &-Figure {
        .Image_ratio_square {
            padding-bottom: 127%;

            @include desktop {
                padding-bottom: 28.5%;
            }
        }

        .Image-Image {
            object-fit: cover;
            border-radius: 0.2rem;
        }

        .Desktop_Background {
            display: none;
        }

        @include desktop {
            .Mobile_Background {
                display: none;
            }

            .Desktop_Background {
                display: block;
            }
        }
    }

    &-Figcaption {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        .Image_ratio_square {
            padding-bottom: 100%;
            margin-bottom: 2%;

            @include desktop {
            }
        }

        .Image-Image {
            object-fit: contain;
            border-radius: 3px;
            border: 10px solid white;

            @include desktop {
                border: 16px solid white;
            }
        }

        .Headline {
            margin: auto;
            max-width: 35rem;
            text-align: center;

            .Desktop_Headline {
                display: none;
            }

            .Mobile_Headline {
                h3 {
                    margin-top: 0;
                    margin-bottom: 0; 
                }

                h2 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            @include desktop {
                max-width: 46rem;
                margin-left: 20%;
                text-align: left;

                .Desktop_Headline {
                    display: initial;
                }

                .Mobile_Headline {
                    display: none;
                }

                h2 {
                    margin-bottom: 0.4rem;
                }
            }
        }

        .Content {
            width: 50%;
            margin: 0 auto;
            margin-top: 3.2rem;

            .Button {
                width: 100%;
            }

            @include desktop {
                width: 40%;
                margin: 0 auto;
                margin-left: 41%;
            }
        }

        @include desktop {
            height: auto;
        }
    }
}
