/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

.GiftCardForm {
    &-FormWrapper {
        order: 19;

        @include mobile {
            padding-right: 14px;
            padding-left: 14px;
        }

        .Field {
            margin-top: 20px;

            input {
                width: 100%;
            }

            &-Label {
                font-family: $ff-pluto-heavy;
                font-size: 16px;
                padding-bottom: 12px;
            }
        }
    }

    &-Preview {
        margin-top: 20px;
    }

    &-Row {
        display: flex;
        justify-content: space-between;

        .Field {
            width: 49%;
        }
    }

    &-Modal {
        &-Wrapper {
            font-size: 16px;
            text-align: center;

            &-Logo {
                width: 230px;
                margin: 0 auto;

                .Image {
                    padding-bottom: 30%;
                }
            }

            &-Title {
                margin-top: 26px;
                font-family: $ff-pluto-heavy;
                font-size: 28px;
            }

            &-Description {
                margin-top: 20px;
                line-height: 20px;

                div {
                    margin-top: 10px;
                }
            }

            &-Message {
                margin-top: 20px;
                color: $c-hbb-green;
            }

            &-Design {
                width: 50%;
                margin: 20px auto;
            }

            .Button {
                margin-top: 20px;
            }
        }
    }
}
