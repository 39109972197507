/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NavigationTabs {
    background-color: $c-hbb-white;
    border-top: 1px solid $c-hbb-green;

    &-Nav {
        height: 51px;
    }
    .Header-Button {
        &_type{
            &_home {

                &::before, &::after {
                    display: none;
                }

                &.NavigationTabs-Icon_isActive{
                    @include home-button-green;
                }
            }

            &_minicart {
                @include minicart-button-black;

                &::before, &::after {
                    display: none;
                }

                &.NavigationTabs-Icon_isActive{
                    @include minicart-button-green;
                }
            }

            &_account {
                @include account-button-black;

                &::before, &::after {
                    display: none;
                }

                &.NavigationTabs-Icon_isActive{
                    @include account-button-green;
                }
            }

            &_menu {
                @include menu-button-black;

                &::before, &::after {
                    display: none;
                }

                &.NavigationTabs-Icon_isActive{
                    @include menu-button-green;
                }
            }
        }
    }
}
