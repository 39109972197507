/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Manuel Trinidad <info@scandiweb.com>
 * @author  Mikheili Papiashvili <info@scandiweb.com>
 */

.MyAccountGiftCardInput {
    &-Button {
        @include button;

        margin-top: 1.6rem;

        @include desktop {
            margin-left: -7rem;
            margin-top: 0;
        }
    }

    &-Input {
        width: 100%;

        @include desktop {
            width: 38rem;
        }
    }

    &-Wrapper {
        p {
            margin-bottom: 1.2rem;
            font-family: $ff-pluto-heavy;
        }
    }
}

.MyAccountGiftCard {
    &-Wrapper {
        display: grid;
        gap: 20px;
        margin-bottom: 2.4rem;

        @include desktop {
            grid-template-columns: 1fr 1fr;
        }

        @include tablet {
            grid-template-columns: 1fr;
        }
    }
}
