/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Reusable {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 11px;

    @include desktop {
        gap: 20px;
    }
}

.Reusable-Column {
    width: 100%;
    grid-column: span 8;

    @include desktop {
        grid-column: span 4;
    }
}

.TwoColumns .Reusable-Column:first-child {
    padding-bottom: 60px;

    @include desktop {
        padding-bottom: 0;
        padding-right: 25px;
    }
}

.TwoColumns .Reusable-Column:last-child {
    @include desktop {
        padding-left: 25px;
    }
}
