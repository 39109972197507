/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Sergejs Erastovs <info@scandiweb.com>
 * @author  Anastasija Zubkova <info@scandiweb.com>
 */

.CardDesignSelection {
    order: 18;
    margin: 12px 0;
    width: 100%;

    @include mobile {
        width: 320px;
        padding: 16px;
    }

    &-Title {
        font-family: $ff-pluto-heavy;
        font-size: 16px;
        color: $c-hbb-black;
        margin-bottom: 12px;
    }

    &-Input {
        display: none;

        &:checked + label {
            border: 1px solid var(--primary-base-color);
        }
    }

    &-Label {
        height: 95px;
        width: 132px;
        border-radius: 8px;
        padding: 8px;
        position: relative;
        cursor: pointer;
    }

    &-Image {
        height: 100%;
        padding: 0;
        cursor: pointer;
    }

    &-Wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}
