/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gift-card-scandipwa
 * @author  Egils Eglitis <info@scandiweb.com>
 * @author  Mikheili Papiashvili <info@scandiweb.com>
 */

:root {
    --giftcard-error: #fae5e5;
}

.CheckoutGiftCardInput {
    padding-bottom: 3.3rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;

    @include desktop {
        padding: 1.8rem 0 0;
        display: block;
    }

    &-Input {
        width: 100%;
        margin: 2px 0 6px 0;
        grid-column: span 8;

        @include desktop {
            width: 60%;
            margin: 12px 10px 10px 0;
        }

        @include tablet {
            width: 50%;
        }
    }

    & &-AddButton {
        @include desktop {
            margin-right: 10px;
            width: 18%;
        }

        @include tablet {
            width: 21%;
        }
    }

    & &-CheckButton {
        @include desktop {
            width: 15%;
        }

        @include tablet {
            width: 18%;
        }
    }

    & &-AddButton,
    & &-CheckButton {
        grid-column: span 4;
        display: inline-flex;
        justify-content: center;
    }

    &-GiftCardColumn {
        padding: 10px;
    }

    &-GiftCardRow {
        grid-column: span 8;
        background-color: $c-hbb-light-gray;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 0;
        padding: 10px;
    }

    &-Header {
        font-family: $ff-pluto-heavy;
        margin-bottom: 0;
        grid-column: span 8;
    }

    &-Title {
        font-size: 1.2rem;
        margin-bottom: 20px;
        font-family: $ff-pluto-heavy;
    }

    &-Value {
        color: var(--black);
        font-size: 1.2rem;
        font-family: $ff-pluto-regular;
        margin-bottom: 0;
    }
}

.CheckoutGiftCard {
    &-Wrapper {
        margin-bottom: 2.4rem;
        grid-column: span 8;
    }
}
