/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.HomepageCategories {
    @include desktop {
        grid-gap: 1.2rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 3px;
    }

    &-Figure {
        margin-top: 1.2rem;

        @include mobile {
            margin-top: 1.4rem;
        }

        @include after-mobile {
            margin-top: 5px;
            height: 400px;
        }

        .Image {
            height: 100%;
            display: block;
            padding-bottom: 0;

            @include mobile {
                height: 0;
                padding-bottom: 56.5%;
            }

            &-Image {
                object-fit: cover;
            }
        }
    }

    &-Figcaption {
        @include after-mobile {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
        }

        &_isPlaceholder {
            padding-bottom: 31px;
        }
    }

    a {
        display: block;

        + a {
            margin-left: 0;
        }
    }
}
