/* pluto-regular */
@font-face {
    font-family: 'PlutoRegular';
    src: local(''),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/pluto-regular.woff2') format('woff2'),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/pluto-regular.woff') format('woff');
}

/* pluto-heavy */
@font-face {
    font-family: 'PlutoHeavy';
    src: local(''),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/pluto-heavy.woff2') format('woff2'),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/pluto-heavy.woff') format('woff');
}
