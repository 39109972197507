/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --gdpr-cookies-box-shadow-color: rgba(0, 0, 0, .3);
    --gdpr-cookies-button-padding: 5px 15px;
    --gdpr-cookies-group-scrollbar-thumb-color: #e8e8e8;
    --gdpr-cookies-group-scrollbar-track-color: #fff;
}

.GdprCookiesPopup {
    align-items: flex-start;
    justify-content: start;

    .Popup {
        &-Content {
            position: fixed;
            bottom: 0;
            border-radius: 0;
            max-height: 100%;
            max-width: 400px;
            overflow-y: hidden;
            padding: 14px 14px 14px 14px;

            @include after-mobile {
                box-shadow: 0 0 12px 2px var(--gdpr-cookies-box-shadow-color);
                min-width: 400px;
                padding: 0 24px 14px 0;
                height: 100vh;
                position: unset;
                bottom: unset;
            }
        }

        &-CloseBtn {
            display: none;
        }
    }

    &-Close {
        @include close-button;

        display: unset;
        position: absolute;
        z-index: 10;
        top: -1.1rem;
        right: -0.1rem;
        width: 2rem;
        height: 2rem;

        @include desktop {
            top: -0.2rem;
            right: -1.6rem;
        }
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &-Actions {
        display: grid;
        gap: 15px;
        height: max-content;
        padding-top: 14px;

        @include after-mobile {
            padding: 24px 0 24px 24px;
        }
    }

    &-Buttons {
        display: flex;
        flex-direction: column;

        .Button {
            margin-top: 15px;
        }
    }

    &-Description {
        font-size: 16px;
        margin: 0;

        @include mobile {
            max-height: 20vh;
            overflow-y: auto;
        }

        a {
            color: var(--gdpr-cookie-group-more-info-color);
        }
    }

    &-Groups {
        display: none;

        @include desktop {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;

            @include after-mobile {
                direction: rtl;
                margin-left: 5px;
                padding-top: 25px;
            }

            &::-webkit-scrollbar {
                width: 4px;

                &-thumb {
                    background: var(--gdpr-cookies-group-scrollbar-thumb-color);
                    border-radius: 2px;
                }

                &-track {
                    background-color: var(--gdpr-cookies-group-scrollbar-track-color);
                    box-shadow: none;
                }
            }
        }
    }
}
