/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-slider-color: #{$c-hbb-white};
}

.homepage-slider {
    h1 {
        color: var(--homepage-slider-color);
        margin-bottom: 3rem;

        @include mobile {
            margin-bottom: 1.4rem;
        }
    }

    .Slider-Crumbs {
        bottom: 2.4rem;

        @include mobile {
            bottom: 2.8rem;
        }
    }

    .SliderWidget-Figcaption {
        text-align: center;

        @include desktop {
            padding-top: 6rem;
        }
    }
}
