/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

:root {
    --homepage-button-background: #fff;
    --homepage-button-color: #fff;
}
.Siopa_Block {
    width: 100%;
    height: 100%;

    &-Figure {
        height: 100%;

        .Image {
            height: 100%;
            display: block;

            &-Image {
                object-fit: cover;
            }
        }

        .Image_ratio_square {
            padding-bottom: 70%;
            border-radius: 0.2rem;
        }

        img {
            width: 100%;
            height: 100%;
        }

        a {
            display: inline;
        }

        @include mobile {
        }
    }
    &-Figcaption {
        --button-background: white;
        --button-color: white;
        --button-border: white;
        --button-hover-background: transparent;
        --button-hover-color: white;
        --button-hover-border: white;
        --button-border-width: 2px;

        .Image {
            width: 170px;
            margin-left: auto;
            margin-right: auto;

            @include desktop {
                width: 225px;
            }
        }

        .Image_ratio_square {
            padding-bottom: 17%;
        }

        h2 {
            color: white;

            @include tablet {
                margin-bottom: 1rem;
                margin-top: 2rem;
            }
        }

        a {
            color: white;
            font-weight: bold;
            text-decoration: none;
        }

        .Button {
            border-color: white;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5rem;
            line-height: 1.5;
            border-radius: 3px;
            cursor: pointer;
            border-width: 2px;
            border: var(--button-border-width) solid var(--button-border);

            @include mobile {
                margin-top: 0;
            }

            @include tablet {
                margin-top : 0;
            }
        }

        @include mobile {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            text-align: center;

            .Image_ratio_square {
                padding-bottom: 12%;
            }

            .Image-Image {
                object-fit: contain;
            }
        }

        @include after-mobile {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
            min-width: 35rem;
        }

        &_isPlaceholder {
            padding-bottom: 31px;
        }
    }
}
