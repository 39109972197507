/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin button-disabled {
    opacity: .25;
    cursor: not-allowed;
}

@mixin button {
    text-decoration: none; // because we never need underlines in buttons

    display: inline-block;
    border-radius: 3px;
    font-family: $ff-pluto-heavy;
    font-size: 1.6rem;
    line-height: 1.5;
    text-transform: uppercase;
    padding: var(--button-padding);
    color: var(--button-color);
    background-color: var(--button-background);
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: 0.25s;
    cursor: pointer;
    text-align: center;

    @include mobile {
        font-size: 1.54rem;
    }

    border: {
        width: var(--button-border-width);
        style: solid;
        color: var(--button-border);
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:not([disabled]) {
        &:hover,
        &:focus {
            @include after-mobile {
                border-color: var(--button-hover-border);
                background-color: var(--button-hover-background);
                color: var(--button-hover-color);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_hasCartIcon {
        span {
            @include white-minicart-button;
            margin-right: 12px;
        }
    }

    &_isHollow {
        color: var(--button-background);
        background: transparent;

        span {
            @include green-arrow-right-button;
            margin-left: 8px;
        }

        &:hover,
        &:focus {
            span {
                @include white-arrow-right-button;

                @include mobile {
                    @include green-arrow-right-button;
                }
            }
        }
    }

    &_isHollowWhite {
        border-color: var(--button-color);
        background: transparent;
        --button-hover-background: var(--button-color);
        --button-hover-color: #{$c-hbb-black};
        --button-hover-border: var(--button-color);

        span {
            @include white-arrow-right-button;
            margin-left: 8px;
        }

        &:hover,
        &:focus {
            span {
                @include black-arrow-right-button;

                @include mobile {
                    @include white-arrow-right-button;
                }
            }
        }
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);

        margin: 0;
        padding: 0;

        span {
            @include next-arrow-simple-button;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

@mixin button-radio {
    min-height: 88px;
    padding-right: 1.2rem;

    &::after {
        height: 16px;
        width: 16px;
        background-color: $c-hbb-white;
        border-radius: 50%;
        opacity: 1;
        top: -20%;
        border: 2px solid $c-hbb-grey;
        display: inline-block;
        transform: none;
    }

    &_isSelected {
        &::after {
            border: 6px solid $c-hbb-green;
            height: 8px;
            width: 8px;
        }
    }
}
