/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --header-background-color: #A2C641;
    --header-background-color-active: #2f2d2e1a;
    --header-desktop-height: 84px;
    --header-mobile-height: 60px;
    --header-total-height: 60px;
    --menu-desktop-height: 52px;
    --header-color: #{$c-hbb-black};
}

.Header {
    display: flex;
    flex-direction: column;
    height: var(--header-mobile-height);
    background-color: $c-hbb-white;
    border-bottom: none;

    @include desktop {
        height: var(--header-desktop-height);
    }

    &-Title {
        @include mobile {
            text-transform: none;
        }

        &_isPrerender {
            margin-top: 2rem;
        }
    }

    .MyAccountOverlay {
        top: 51px;
        right: -48px;
    }

    .CartOverlay {
        top: 51px;
        right: 0;
    }

    .Header-MinicartIcon {
        @include minicart-button-black;
    }

    &-Wrapper {
        @include desktop {
            --header-total-height: calc(var(--header-desktop-height) + var(--menu-desktop-height));
            margin-bottom: var(--header-total-height);
        }
    }

    &_name {
        &_cart {
            @include mobile {
                border-bottom: 1px solid $c-hbb-green;
            }

            .Header-MinicartIcon {
                @include minicart-button-green;
            }

            &_overlay {
                .Header-Button_type_minicart,
                .Header-MinicartTitle {
                    @include after-mobile {
                        --header-color: var(--primary-base-color);
                    }
                }
                .Header-MinicartIcon {
                    @include minicart-button-green;
                }
            }

            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_menu {
            @include mobile {
                background-color: $c-hbb-light-gray;
            }

            &_subcategory {
                border-bottom: 1px solid var(--primary-base-color);
            }
        }

        &_search {
            @include mobile {
                background-color: $c-hbb-light-gray;
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account {
                @include desktop {
                    margin-left: 1rem;
                }
            }
        }

        &_checkout,
        &_category {
            @include mobile {
                border-bottom: 1px solid $c-hbb-green;
            }
        }

        &_checkout {
            .Header-Button,
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: initial;
                }
            }
        }
    }

    &-LogoWrapper {
        --header-logo-height: 40px;
        --header-logo-width: 176px;

        position: absolute;
        height: var(--header-logo-height);
        width: var(--header-logo-width);

        @include desktop {
            position: relative;
            height: auto;
            width: auto;
            margin: 0;
        }

        .Image-Image {
            position: relative;
        }
    }

    &-MinicartButtonWrapper {
        margin: 0;
        padding: 0;
    }

    &-MinicartItemCount {
        position: absolute;
        top: -6px;
        width: 16px;
        min-width: 16px;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0;
        padding-top: 2px;
        border-radius: 50%;
        background-color: $c-hbb-green;
        color: $c-hbb-white;
        font-family: $ff-pluto-heavy;
        font-size: $fs-caption;
        font-weight: $fw-bold;
        line-height: 1.4rem;

        @include desktop {
            top: -8px;
            left: 93px;
        }
    }

    &-Nav {
        max-width: var(--content-wrapper-width);
        width: 100%;
        border-bottom: $c-hbb-green solid 1px;
        height: 6rem;

        @include desktop-xs {
            min-height: 40px;
        }

        @include desktop {
            height: var(--header-desktop-height);
            margin: 1.3rem auto 1.1rem;
            min-height: 60px;
            padding: 0 1.2rem;
            justify-content: space-between;
            border-bottom: 0;
        }
    }

    &-Button {
        &_type {
            &_back {
                margin-right: 1.4rem;
                @include back-button;
            }

            &_home {
                @include home-button-black;
            }

            &_account {
                @include desktop {
                    margin-left: 1rem;
                }
            }

            &_minicart, &_search, &_account {
                padding: 0;
            }

            &_minicart {
                @include desktop {
                    margin-left: 3.2rem;
                }
            }

            &_clear {
                @include remove-button;

                &::after {
                    box-shadow: none;
                }

                &::after,
                &::before {
                    border: none;
                }
            }
        }
    }

    &-MyAccountTitle {
        padding-right: 0;
        color: $c-hbb-black;
        text-transform: inherit;
    }

    &-MinicartButtonWrapper {
        height: 20px;
    }

    &-MinicartTitle {
        margin-left: 0;
        margin-right: 0;
        text-transform: inherit;
    }

    &-MinicartIcon {
        &::before, &::after {
            display: none;
        }

        @include desktop {
            margin-left: 1rem;
            top: 0;
        }
    }

    &_isCheckout {
        .Header-Nav {
            @include desktop {
                justify-content: space-between;
            }
        }
    }

    &-Activities {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    &-NavigationSteps {
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
        padding-left: 10px;
        width: 100%;

        @include mobile-xs {
            padding-left: 0;
            padding-right: 0;
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-Step {
        color: $c-hbb-grey;
        font-family: $ff-pluto-heavy;
        font-size: 20px;
        width: 100%;

        &_isCurrent {
            color: $c-hbb-black;
        }
    }
}

.AdditionalStyles {
    &-PDP {
        .zopim:nth-child(3) {
            @include mobile {
                bottom: 140px !important;
            }
        }
    }

    &-Cart {
        .zopim:nth-child(3) {
            @include mobile {
                bottom: 194px !important;
            }
        }
    }

    &-Wishlist {
        .zopim:nth-child(3) {
            @include mobile {
                bottom: 147px !important;
            }
        }
    }

    &-Checkout {
        .zopim:nth-child(3) {
            @include mobile {
                bottom: 116px !important;
            }
        }
    }

    &-CheckoutSuccess {
        .zopim:nth-child(3) {
            @include mobile {
                bottom: 136px !important;
            }
        }
    }
}

.hideOnScroll {
    .AdditionalStyles {
        &-Category {
            .zopim:nth-child(3) {
                @include mobile {
                    bottom: 20px !important;
                    transition: bottom 0.3s;
                }
            }
        }
    }
}
