/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    background-color: $c-hbb-black;

    &-Content {
        background-color: $c-hbb-black;
        color: $c-hbb-white;
        padding-top: 2rem;
    }

    @include desktop {
        padding: 0 1.2rem;
    }

    .CmsBlock-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        @include desktop {
            display: contents;
        }
    }

    &-Columns {
        padding: 2rem 1.4rem;
        flex-direction: column;
        align-items: stretch;

        @include desktop {
            flex-direction: row;
            padding: 0;
        }
    }

    &-Column {
        width: 100%;
        margin-bottom: 1.6rem;
        border-bottom: 1px solid #c4c4c480;

        @include desktop {
            width: initial;
            border-bottom: 0;
            padding-right: 2rem;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-bottom: 0;
            }
        }

        &:first-child {
            margin-top: 2rem;
        }

        &_type {
            &_isItemsHorizontal {
                display: flex;
                justify-content: center;
            }

            &_isNewsLetter {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                border-bottom: 0;

                @include desktop {
                    margin: 0;
                    width: 30rem;
                    padding-right: 0;
                    border-bottom: 0;
                    text-align: start;
                    align-items: flex-start;

                }
            }

        }

        input[type="checkbox"]:checked {
            ~.Footer-ColumnContent {
                margin-bottom: 1.6rem;

                .Footer-ColumnItem {
                    display: block;
                }
            }

            + .Footer-ColumnTitle {
                &::after {
                    content: '-';

                    @include desktop {
                        content: none;
                    }
                }
            }
        }
    }

    &-ColumnContent {
        text-align: start;

        &_type {
            &_isItemsHorizontal {
                display: flex;
                justify-content: center;
                flex-direction: row;
                padding-bottom: 4rem;

                @include desktop {
                    justify-content: start;
                }
            }

            &_isSocialNetwork {
                display: flex;
                align-items: center;

                @include desktop {
                    align-items: start;
                }
            }
        }
    }

    &-Input {
        display: none;
    }

    &-isNewsLetterTitle {
        width: 100%;
        padding: 0 2rem;

        @include desktop {
            text-align: start;
            padding: 0;
            margin: 1.6rem 0;
        }
    }

    &-ColumnTitle {
        margin: 1.6rem 0;
        text-transform: capitalize;
        text-align: start;
        font-family: $ff-pluto-heavy;
        line-height: 2.4rem;

        &::after {
            content: '+';
            float: right;
            padding-right: 0.2rem;
            font-family: "PlutoRegular";
            line-height: 2.4rem;
            font-size: 2.6rem;

            @include desktop {
                content: none;
            }
        }
    }

    &-ColumnItem {
        display: none;
        font-size: $fs-caption;
        line-height: 1.6rem;
        color: $c-hbb-grey;

        @include desktop {
            display: flex;
        }
    }

    &-Conditions {
        order: 1;

        @include desktop {
            padding-right: 2rem;
            order: 2;
        }
        >a {
            color: $c-hbb-grey;
        }
    }

    &-Copyright {
        padding-top: 2rem;
        font-size: $fs-caption;
        width: 100%;
        order: 3;

        @include desktop {
            width: auto;
            text-align: start;
            order: 1;
            flex-grow: 1;
            padding-top: 0;
        }
    }

    &-CopyrightContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 2rem 0 2.4rem;
        border-top: 1px solid #c4c4c480;
    }

    &-CopyrightContentWrapper {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
        background-color: $c-hbb-black;
        color: $c-hbb-grey;

        @include desktop {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-SocialNetworkTitle {
        display: none;

        @include desktop {
            display: initial;
            margin-top: 0;
            margin-bottom: 1.6rem;
            text-align: start;
        }
    }

    &-Icon {
        height: 24px;
        width: 24px;
    }

    &-Logo {
        display: block;
        margin: 0 auto 3.2rem;

        @include desktop {
            margin: 0 0 4rem;
        }

        .Image {
            position: relative;
            width: 176px;
            height: 40px;
            padding-bottom: 0;
        }

    }

    &-Link {
        margin-right: 4rem;

        @include desktop {
            margin-right: 2.4rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &-PrivacyPolice {
        order: 2;

        >a {
            color: $c-hbb-grey;
        }
    }

    &-PayMethods {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 4rem;

        img {
            width: auto;
            margin-right: 1rem;

            &:first-child {
                margin-right: 0;
            }
        }
    }
}
